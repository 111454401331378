import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../services/authentication.service";
import {MatDialog} from "@angular/material/dialog";
import {AlertsService, CLToastType} from "@clavisco/alerts";
import {DataStorageService} from "../services/data-storage.service";
import {GlobalService} from "../services/global.service";
import {MenuService} from "../services/menu.service";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor (
    public auth: AuthenticationService,
    private router: Router,
    public dialog: MatDialog,
    private alertService: AlertsService,
    private dataStorage: DataStorageService,
    private globalService: GlobalService,
    private menuService: MenuService,
  ) {
  }

  /**
   * Determines if the route can be activated based on the user's authentication status and access permissions.
   *
   * @param route - The route that is being activated, represented by an `ActivatedRouteSnapshot`.
   * @param state - The router state, represented by a `RouterStateSnapshot`.
   * @returns An `Observable<boolean | UrlTree>`, `Promise<boolean | UrlTree>`, `boolean`, or `UrlTree` indicating if the route can be activated.
   */
  canActivate (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const path: string = state.url.substring(1);
    if (this.auth.IsActhenticated()) {
      if (this.menuService.GetLocalMenu()) {
        const node = this.globalService.FindNodeByRoute(this.menuService.GetLocalMenu(), path);
        if (node) {
          const actualPath: string = this.GetRouteWithoutParams(route);
          this.menuService.SetCurrentPageTitle(path);
          return true;
        } else {
          this.alertService.Toast({
            message: 'No tienes acceso a esta sección, para continuar, comuniquese con administración',
            type: CLToastType.ERROR,
            duration: 2000
          });
          this.router.navigate(['/Home']);
          return false;
        }
      }
      const actualPath: string = this.GetRouteWithoutParams(route);
      this.menuService.SetCurrentPageTitle(path);
      return true;
    } else {
      this.alertService.Toast({message: 'No está autenticado, por favor inicie sesión', type: CLToastType.ERROR});
      this.auth.Logout();
      this.router.navigate(['/Login']);
      return false;
    }
  }
  private GetRouteWithoutParams (route: ActivatedRouteSnapshot): string {
    const urlSegments: string[] = route.url.map(segment => segment.path);
    if (urlSegments.length > 0) {
      return urlSegments[0];
    }
    return '';
  }
}
