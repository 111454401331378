import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {OverlayModule, OverlayService} from '@clavisco/overlay';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {Miscellaneous} from '@clavisco/core';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {environment} from '../environments/environment';
import {SharedModule} from './shared/shared.module';
import {LoginContainerComponent} from './pages/login-container/login-container.component';
import {PagesComponent} from './pages/pages.component';
import {LoginModule} from '@clavisco/login';
import {MenuModule} from '@clavisco/menu';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppInterceptor} from "./interceptors/app-interceptor";
import {ActionCenterPushInterceptor} from "./interceptors/action-center-push.interceptor";

@NgModule({
  declarations: [
    AppComponent,
    PagesComponent,
    LoginContainerComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    LoginModule,
    MenuModule,
    OverlayModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recatpchaSiteKey
    },
    { provide: 'OverlayService', useClass: OverlayService },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ActionCenterPushInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.PagedRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Miscellaneous.Interceptors.HttpAlertInterceptor,
      multi: true
    }

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
