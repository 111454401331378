import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {IMenuNode} from '@clavisco/menu';
import {MenuService} from "../services/menu.service";
import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from '@clavisco/linker';
import {Router} from '@angular/router';
import {DataStorageService} from '../services/data-storage.service';
import {AlertsService, CLToastType} from '@clavisco/alerts';
import {MatDialog} from '@angular/material/dialog';
import {CLPrint, Repository, Structures} from '@clavisco/core';
import {environment} from '../../environments/environment';
import {IMenuItem} from "../interfaces/menu";
import {GlobalService} from "../services/global.service";
import {AuthenticationService} from "../services/authentication.service";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})

export class PagesComponent implements OnInit {

  Toggle: boolean = true;

  MenuId: string = 'main-menu';
  MenuNodes: IMenuNode[] = [];
  LogoDefault: string = '../../assets/img/clavisco-tools-white.png';
  LogoSrc: string = this.LogoDefault;
  User: string = '';
  Title: string = environment.HomeTitle;
  CurrentPageTitle: string = '';
  CurrentModuleTitle: string = '';
  profileDefault: string = '../../assets/img/user.png';
  profile: string = this.profileDefault;
  selectedUser: {Email:string} = {Email: "Sys@clavisco.com"};

  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  };

  constructor (
    private router: Router,
    private alertService: AlertsService,
    private globalService: GlobalService,
    private dataStorage: DataStorageService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private menuService: MenuService,
    @Inject('LinkerService') private linkerervice: LinkerService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.globalService.currentPageTitle.subscribe((next: string): void => {
      this.CurrentPageTitle = next;
    });

    this.globalService.currentModuleTitle.subscribe(next => this.CurrentModuleTitle = next);
    this.menuService.MenuItem.subscribe((next: IMenuItem[]) => {
      this.MenuNodes = next as IMenuNode[];
      this.linkerervice.Publish(
        {
          CallBack: CL_CHANNEL.INFLATE,
          Data: JSON.stringify(next),
          Target: this.MenuId
        }
      );
    });

    this.menuService.CurrentPageTitle.subscribe((next) => {
      this.CurrentPageTitle = next;
    });
    this.globalService.UserAvatar.subscribe(value => {
      this.profile = value ? value : this.profileDefault;
    });

  }


  ngOnInit (): void {
    this.changeDetectorRef.detectChanges();
    const userId: number = this.dataStorage.GetUserId();

    Register(this.MenuId, CL_CHANNEL.OUTPUT, this.ClickMenuOption, this.callbacks);
    this.linkerervice.Flow()?.pipe(
      StepDown<CL_CHANNEL>(this.callbacks),
    ).subscribe({
      next: callback => Run(callback.Target, callback, this.callbacks.Callbacks),
      error: error => CLPrint(error, Structures.Enums.CL_DISPLAY.ERROR)
    });

    this.OnLoadData();
  }

  /**
   * Loads user and menu data and updates the application state.
   *
   * Performs concurrent requests to fetch user information and menu data.
   */
  private OnLoadData (): void {
    this.menuService.Get().subscribe({
      next: response => {
        if (response.Data) {
          this.menuService.MenuItem.next(response.Data);
          this.MenuNodes = response.Data;
          Repository.Behavior.SetStorage<IMenuItem[]>(response.Data, 'KeyMenu');
        }
      },
      error: error => {
        CLPrint(error, Structures.Enums.CL_DISPLAY.ERROR);
      }
    });
  }

  /**
   * Handles the click on a menu option and performs actions based on the option key.
   *
   * - For options 'tcm', 'tmp', or 'acb', shows an error message if the user lacks the corresponding permission.
   * - For the 'exit' option, logs out the user and redirects to the login page.
   *
   * @param {ICLEvent} _event - Event containing the selected menu option.
   */
  ClickMenuOption = (_event: ICLEvent): void => {
    if (_event) {
      const NODE_OPTION: IMenuItem = JSON.parse(_event.Data);
      switch (NODE_OPTION.Key) {
        case 'exit':
          this.authService.Logout();
          this.router.navigateByUrl('/Login');
          break;
      }
    }
  };

  /**
   * Toggles the menu state and publishes the new state.
   *
   * Changes the value of `Toggle` and publishes the updated state to a specific channel.
   *
   * @returns {void}
   */
  ToggleMenu (): void {
    this.Toggle = !this.Toggle;
    this.linkerervice.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Target: this.MenuId,
      Data: JSON.stringify(this.Toggle)
    });
  }

  /**
   * Opens a dialog to display the selected user's information.
   *
   * If a user is selected (`selectedUser`), it opens a dialog with the `ModalUsersComponent` component
   * and passes the user's information to the dialog. If no user is selected, it shows a warning message.
   *
   * @returns {void}
   */
  OpenUserDialog (): void {

  }
}
